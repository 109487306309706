<template>
  <div class>
    <div class="d-flex mb-2">
      <span class="page-header-title">Product List</span>
    </div>
    <CCard>
      <CCardBody>
        <div class="d-md-flex d-sm-flex justify-content-end mt-3">
          <div class="mr-0 mr-lg-3 mr-md-3 mr-xl-3 mr-sm-2">
            <b-form-select
              v-model="selectedCategory"
              @change="selectCategory(selectedCategory)"
              :options="categoriesOptions"
              class="mb-2"
            ></b-form-select>
          </div>
          <div>
            <b-input-group class="mb-2">
              <template #append>
                <b-input-group-text @click="onSearch(searchText)">
                  <CIcon name="cil-search" class="cicon cursor" />
                </b-input-group-text>
              </template>
              <b-form-input
                v-model="searchText"
                placeholder="Product Name"
                v-on:keyup.enter="onSearch(searchText)"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="mr-0">
            <b-button
              variant="primary"
              class="float-right ml-3 mt-3 mt-lg-0 mt-md-0 mt-sm-0"
              @click="showAddModal()"
            >
              <em class="fa fa-plus" aria-hidden="true"></em>
              Add New
            </b-button>
          </div>
        </div>

        <div class="table-responsive mt-2">
          <CustomTableUser
            class="vertical-align-middle table-responsive account_table"
            :items="productList"
            hover
            striped
            border
            :small="'small'"
            fixed
            caption
            :fields="fields"
            :per-page="perPage"
            :total="total"
            :loadTableData="loadTableData"
            :rowSelectHandler="rowClickHandler"
            :onRowDeleteHandler="deleteTableData"
            :onRowEditHandler="editTableData"
            :rowFliterHandler="rowFliterHandler"
            :currentPagination="currentPageOfManagers"
            :onClickImage="showImage"
          />
        </div>
      </CCardBody>
    </CCard>
    <!-- Add New Category Modal -->
    <b-modal id="add-category-modal" title="Add New Product" size="lg">
      <b-form @submit.prevent="addProduct">
        <label class="asterisk font-weight-bold">Name </label>
        <p class="notification-input">
          <CInput
            placeholder="name"
            v-model="newProduct.product_name"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.newProduct.product_name"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>

        <label class="asterisk font-weight-bold">Category </label>
        <p class="notification-input">
          <b-form-select
            v-model="newProduct.category_id"
            :options="categoriesOptions"
            class=""
          >
          </b-form-select>
          <show-errors
            class=""
            :control="$v.newProduct.category_id"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <quill-editor
            v-model="newProduct.description"
            ref="notificationQuillEditor"
            :options="editorOption"
            @change="oneEditorChange($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          >
          </quill-editor>
          <show-errors
            class=""
            :control="$v.newProduct.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>

        <label class="mt-2 font-weight-bold asterisk"> Image Upload </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(index)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="$bvModal.hide('add-category-modal')"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="addProduct()"
              >Create
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Edit Category Modal -->
    <b-modal id="edit-category-modal" title="Product Edit" size="lg">
      <b-form @submit.prevent="editProduct">
        <label class="asterisk font-weight-bold">Name </label>
        <p class="notification-input">
          <CInput
            placeholder="name"
            v-model="selectedProduct.product_name"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.selectedProduct.product_name"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>

        <label class="asterisk font-weight-bold">Category </label>
        <p class="notification-input">
          <b-form-select
            v-model="selectedProduct.category_id"
            :options="categoriesOptions"
            class=""
          >
          </b-form-select>
          <show-errors
            class=""
            :control="$v.selectedProduct.category_id"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <quill-editor
            v-model="selectedProduct.description"
            ref="notificationQuillEditor"
            :options="editorOption"
            @change="oneEditorChange($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          >
          </quill-editor>
          <show-errors
            class=""
            :control="$v.selectedProduct.description"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>

        <label class="mt-2 font-weight-bold asterisk"> Image Upload </label>
        <div>
          <label
            class="btn btn-default btn-primary mt-lg-0 mt-md-0 mt-sm-0 mb-0"
          >
            <em class="fa fa-upload" aria-hidden="true"></em> Upload
            <input
              id="fileupload"
              ref="imageUploader"
              type="file"
              hidden
              @change="onFileChange"
              accept="image/png, image/jpeg"
            />
          </label>
          <div v-if="fileName" class="ml-2 mb-2">
            {{ fileName }}
            <em
              class="fa fa-times-circle cursor mt-1 ml-3"
              @click="deleteFile(index)"
              aria-hidden="true"
              style="color: red"
            />
          </div>
        </div>
        <div>
          <span class="mandatory-color error-font">{{ fileErrorMessage }}</span>
        </div>
      </b-form>
      <template v-slot:modal-footer="{}">
        <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
          <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
            <b-button
              size="md"
              variant="secondary"
              class="ml-2 px-2"
              @click="onCancelEdit()"
              >Cancel
            </b-button>
            <b-button
              size="md"
              variant="primary"
              class="ml-2 px-2"
              @click="editProduct()"
              >Save
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Category Details Modal -->
    <b-modal
      id="details-category-modal"
      title="Product Details"
      size="lg"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Name</div>
        <div class="col-lg-10 mt-2 pt-1">
          {{ selectedProduct.product_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Category</div>
        <div class="col-lg-10 mt-2 pt-1">
          {{ selectedProduct.category_name }}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Image</div>
        <div class="col-lg-10 mt-2 pt-1">
          <span v-if="selectedProduct.product_image">
          <img :src=selectedProduct.product_image width="80" height="80px" class="mt-1" />
        </span>
        <span v-else> - </span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Description</div>
        <div class="col-lg-10 mt-2 pt-1">
          <div
            class="ql-snow"
            oncontextmenu="return false;"
            onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false"
          >
            <div
              class="content-manage ql-editor"
              draggable="false"
              v-html="selectedProduct.description"
            ></div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- Confirm Delete Modal -->
    <b-modal
      id="confirm-delete-category"
      hide-header
      class="cancelbtn"
      title="Delete Data"
      ok-title="Yes"
      cancel-title="Cancel"
      @ok="deleteCategory"
    >
      <p class="my-4 wrap">Are you sure you want to delete this Product?</p>
    </b-modal>
    <b-modal id="image-modal" hide-footer title="Image Preview">
      <template v-slot:default>
        <div class="text-center">
          <img :src="selectedImageUrl" alt="Preview" class="img-fluid" />
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { senitizeMtbName, multipleErrorMessages } from "@/shared/utils";
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength } from "vuelidate/lib/validators";
import CustomTableUser from "@/components/Table/TableUser.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-paste-smart";
import { quillEditor, Quill } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageCompress", ImageCompress);
var Block = Quill.import("blots/block");
var sizeof = require("object-sizeof");
Block.tagName = "div";
Quill.register(Block);
import {
  PRODUCT_CATEGORY_DROPDOWN,
  PRODUCT_CREATE,
  PRODUCT_DELETE,
  PRODUCT_DETAILS,
  PRODUCT_LIST,
  PRODUCT_UPDATE,
} from "../../store/actions.type";
export default {
  name: "ProductList",
  components: { CustomTableUser, ShowErrors, quillEditor },
  data() {
    return {
      editorOption: {
        modules: {
          clipboard: {
            allowed: {
              tags: [
                "a",
                "b",
                "strong",
                "u",
                "s",
                "i",
                "p",
                "br",
                "ul",
                "ol",
                "li",
                "span",
                "h1",
                "h2",
                "div",
                "h3",
                "h4",
                "h5",
              ],
              attributes: ["href", "rel", "target", "class", "style"],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {
              uponSanitizeElement(node) {
                console.log(node);
              },
            },
          },
          imageCompress: {
            quality: 0.7, // default
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: "image/jpeg", // default
            debug: true, // default
            suppressErrorLogging: false, // default
          },

          toolbar: [
            // ["link", "emoji", "image"],
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            // [{ header: 1 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme

            [{ align: [] }],
            ["image", "video", "link"],
            ["clean"],
          ],
        },
      },
      selectedCategory: "",
      categoriesData: [{ value: "", text: "Select Category" }],
      categoriesOptions: [],
      productList: [],
      newProduct: { product_name: "", description: "", category_id: "" },
      fileName: "",
      fileErrorMessage: "",
      files: [],
      searchText: "",
      selectedProduct: {
        product_name: "",
        description: "",
        category_id: "",
        category_name: "",
        product_image:"",
      },
      selectedImageUrl: "",
      deleteSelCategory: "",
      productFilter: {
        filters: {
          start: 0,
          limit: 50,
          sort_by: "",
          order_by: "",
        },
        search: "",
        category_id: "",
      },
      perPage: 50,
      total: 0,
      currentPage: 1,
      pageStart: 0,
      sortField: "created_at",
      sortDirection: "desc",
      currentPageOfManagers: 1,

      fields: [
        {
          key: "product_name",
          label: "Product Name",
          _classes: "product_name",
          sortable: true,
        },
        {
          key: "category_name",
          label: "Category Name",
          _classes: "product_name",
          sortable: true,
        },
        {
          key: "product_image",
          label: "Image",
          sorter: false,
          _classes: "tbl-width-50",
        },
        {
          key: "productAction",
          label: "Action",
          sortable: false,
          _classes: "",
        },
      ],
    };
  },
  validations: {
    newProduct: {
      product_name: { required, maxLength: maxLength(100), senitizeMtbName },
      description: { required },
      category_id: { required },
    },
    selectedProduct: {
      product_name: { required, maxLength: maxLength(100), senitizeMtbName },
      description: { required },
      category_id: { required },
    },
  },
  methods: {
    /**
     * @description function to check and validate browsed files
     * @param {file} data
     */
    onFileChange(event) {
      let fileSize = 0;
      for (const element of event.target.files) {
        var ext = element.type;
        fileSize += Math.round(element.size / (1024 * 5));
        if (ext == "image/png" || ext == "image/jpeg") {
          if (fileSize > 1024) {
            this.fileErrorMessage = "Please upload less than 5 mb";
          } else {
            for (let i = 0; i < event.target.files.length; i++) {
              this.files.push(event.target.files[i]);
            }

            this.fileName = event.target.files[0].name;
            this.fileErrorMessage = "";
          }
        } else {
          this.fileErrorMessage = "Please upload correct image file";
        }
        fileSize += Math.round(element.size / (1024 * 5));
      }
    },

    /**
     * Deletes a file from the files array at the specified index.
     *
     * @param {number} index - The index of the file to delete.
     * @return {void} This function does not return anything.
     */
    deleteFile(index) {
      this.fileErrorMessage = "";
      if (this.files.length < 1) {
        this.fileName = "";
        this.deleteImage = true;
      } else {
        this.files.splice(index, 1);
        this.fileName = "";
      }
      this.$refs.imageUploader.value = "";
    },

    /**
     * Displays the image modal with the selected image URL.
     *
     * @param {Object} item - The item object containing the product image URL.
     * @return {void} This function does not return anything.
     */
    showImage(item) {
      this.selectedImageUrl = item.product_image;
      this.$bvModal.show("image-modal");
    },

    /**
     * Hides the image modal.
     *
     * @return {void} This function does not return anything.
     */
    hideModal() {
      this.$bvModal.hide("image-modal");
    },

    /**
     * Selects a category and fetches products based on the selected category ID.
     *
     * @param {number} id - The ID of the category to select.
     * @return {void} This function does not return anything.
     */
    selectCategory(id) {
      this.productFilter.category_id = id;
      this.fetchProducts();
    },

    /**
     * Updates the search text in the product filter and fetches products based on the new search text.
     *
     * @param {string} text - The new search text.
     * @return {void} This function does not return anything.
     */
    onSearch(text) {
      this.productFilter.search = text;
      this.fetchProducts();
    },

    /**
     * Selects a category and fetches products based on the selected category ID.
     *
     * @param {Object} category - The category object to select.
     * @return {Promise<void>} A promise that resolves when the products are fetched.
     */
    filterCategories(category) {
      this.selectedProduct = category;
      this.fetchProducts();
    },

    /**
     * Fetches products based on the current page, per page limit, sort field, sort direction, and product filter.
     *
     * @return {Promise<void>} A promise that resolves when the products are fetched.
     */
    async fetchProducts() {
      this.productFilter.filters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.productFilter.filters.limit = this.perPage;
      this.productFilter.filters.sort_by = this.sortField;
      this.productFilter.filters.order_by = this.sortDirection;
      this.isLoadingFetchProduct = this.$loading.show();
      let data = await this.$store.dispatch(PRODUCT_LIST, this.productFilter);
      this.isLoadingFetchProduct.hide();
      if (_.get(data, "success", true)) {
        this.productList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.productList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * Load table data based on the current page and limit.
     *
     * @param {number} currentPage - The current page number.
     * @param {number} limit - The number of items per page.
     * @return {void}
     */
    loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfPromotions = currentPage;
      this.fetchPromotionList();
    },

    /**
     * Handles the click event on a row.
     *
     * @param {Object} item - The item object representing the row.
     * @return {Promise<void>} A promise that resolves when the function completes.
     */
    async rowClickHandler(item) {
      if (item.id) {
        this.isLoadingRow = this.$loading.show();
        let data = await this.$store.dispatch(PRODUCT_DETAILS, {
          id: item.id,
        });
        this.selectedProduct.product_name = data.product_name || "";
        this.selectedProduct.description = data.description || "";
        this.selectedProduct.category_name = data.category_name || "";
        this.selectedProduct.product_image = data.product_image || "";
        this.isLoadingRow.hide();
        this.showDetailsModal();
      }
    },

    /**
     * Deletes the table data for the specified item.
     *
     * @param {Object} item - The item to be deleted.
     * @return {void}
     */
    deleteTableData(item) {
      this.confirmDelete(item);
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchProducts();
    },

    /**
     * @method editTableData
     * @description redirects edit page
     */
    editTableData(item) {
      this.showEditModal(item);
    },

    /**
     * Shows the add modal and resets the new product form.
     *
     * @return {void}
     */
    showAddModal() {
      this.$v.newProduct.$reset();
      this.files = [];
      this.fileName = "";
      this.fileErrorMessage = "";
      this.newProduct = { product_name: "", description: "", category_id: "" };
      this.$bvModal.show("add-category-modal");
    },

    /**
     * Asynchronously adds a new product to the database.
     * Validates the form data,
     * checks if a file has been uploaded, and
     * displays an error message if there is an error.
     * response message or calls a helper function to display multiple error messages.
     */
    async addProduct() {
      this.$v.$touch();
      if (this.files.length <= 0 && this.fileName == "") {
        this.fileErrorMessage = "Please upload file.";
        return;
      }
      if (sizeof(this.newProduct.description) > 1048576 * 5) {
        this.$toast.error({
          message: "Maximum size is exceeded.",
        });
        return;
      }
      if (!this.$v.newProduct.$invalid) {
        let formData = new FormData();
        formData.append("product_name", this.newProduct.product_name);
        formData.append("description", this.newProduct.description);
        formData.append("category_id", this.newProduct.category_id);
        this.files[0] && formData.append("image", this.files[0]);
        this.fileErrorMessage = "";
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store.dispatch(PRODUCT_CREATE, formData).then((data) => {
          if (_.get(data, "data.success", false)) {
            this.isLoadingSubmitNotify.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("add-category-modal");
            this.$v.newProduct.$reset();
            this.fetchProducts();
          } else {
            this.$v.newProduct.$reset();
            this.$bvModal.hide("add-category-modal");
            this.isLoadingSubmitNotify.hide();
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
      }
    },

    /**
     * Shows the edit modal for the given product item.
     *
     * @param {Object} item - The product item to be edited.
     * @return {void}
     */
    showEditModal(item) {
      this.selectedProduct = { ...item };
      this.fileErrorMessage = "";
      let path = item.product_image || "";
      if (path) {
        const parts = path.split("/");
        // The last element in the array will be the filename
        this.fileName = parts[parts.length - 1];
      } else {
        this.fileName = "";
      }
      this.$bvModal.show("edit-category-modal");
    },

    /**
     * Resets the selected product and hides the edit category modal.
     *
     * @return {void}
     */
    onCancelEdit() {
      this.selectedProduct = {
        product_name: "",
        description: "",
        category_id: "",
        category_name: "",
        product_image: "",
      };
      this.$bvModal.hide("edit-category-modal");
    },

    /**
     * Asynchronously handles the logic for editing a product.
     *
     * @return {Promise<void>} Returns a Promise that resolves when the product has been edited.
     */
    async editProduct() {
      // Edit category logic here
      this.$v.$touch();
      if (this.files.length <= 0 && this.fileName == "") {
        this.fileErrorMessage = "Please upload file.";
        return;
      }
      if (sizeof(this.selectedProduct.description) > 1048576 * 5) {
        this.$toast.error({
          message: "Maximum size is exceeded.",
        });
        return;
      }
      if (!this.$v.selectedProduct.$invalid) {
        let formData = new FormData();
        formData.append("id", this.selectedProduct.id);
        formData.append("product_name", this.selectedProduct.product_name);
        formData.append("description", this.selectedProduct.description);
        formData.append("category_id", this.selectedProduct.category_id);
        this.files[0] && formData.append("image", this.files[0]);
        if (
          this.selectedProduct.product_image != "" &&
          this.selectedProduct.product_image != null &&
          this.fileName == ""
        ) {
          formData.append("isDelete", 1);
        }
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store.dispatch(PRODUCT_UPDATE, formData).then((data) => {
          if (_.get(data.data, "success", false)) {
            this.isLoadingSubmitNotify.hide();
            this.$toast.removeAll();
            this.$toast.success({
              message: _.get(data, "data.message", ""),
            });
            this.$bvModal.hide("edit-category-modal");
            this.$v.newProduct.$reset();
            this.files = [];
            this.fileName = "";
            this.fileErrorMessage = "";
            this.fetchProducts();
          } else {
            this.$v.newProduct.$reset();
            this.$bvModal.hide("edit-category-modal");
            this.isLoadingSubmitNotify.hide();
            this.files = [];
            this.fileName = "";
            this.fileErrorMessage = "";
            let message = _.get(data, "message", "");
            if (message.length > 0) {
              this.$toast.error({
                message: message,
              });
            } else {
              this.$toast.removeAll();
              let messageObj = _.get(data, "message", "");
              multipleErrorMessages(this.$toast, messageObj);
            }
          }
        });
      }
    },

    /**
     * Show the details modal for the category.
     *
     * @return {void} No return value
     */
    showDetailsModal() {
      this.$bvModal.show("details-category-modal");
    },

    /**
     * Confirm the deletion of a category and show the confirmation modal.
     *
     * @param {Object} item - The category item to be deleted.
     * @return {void}
     */
    confirmDelete(item) {
      this.deleteSelCategory = item;
      this.$bvModal.show("confirm-delete-category");
    },

    /**
     * Asynchronously deletes a category from the store and updates the product list.
     *
     * @return {Promise<void>} Promise that resolves when the category is successfully deleted and the product list is updated.
     */
    async deleteCategory() {
      if (this.deleteSelCategory.id) {
        let data = await this.$store.dispatch(PRODUCT_DELETE, {
          id: this.deleteSelCategory.id,
        });
        if (_.get(data.data, "success", false)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          if (this.productList.length == 1) {
            this.pageStart = 0;
            this.currentPage = 1;
            this.perPage = 50;
          }

          this.fetchProducts();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
      // Delete category logic here
      this.$bvModal.hide("confirm-delete-category");
    },
  },

  /**
   * Initializes the component when it is created.
   *
   * @return {Promise<void>} A promise that resolves when the component is initialized.
   */
  async created() {
    let dropdown = await this.$store.dispatch(PRODUCT_CATEGORY_DROPDOWN);
    let options = [];

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    }

    for (let i = 0; i < dropdown.length; i++) {
      options.push({
        text: truncateText(dropdown[i].category_name, 30),
        value: dropdown[i].id,
      });
    }

    this.categoriesOptions = _.concat(...this.categoriesData, options);

    this.fetchProducts();
  },
};
</script>
<style>
.product_name {
  width: 200px;
}
.category_description {
  width: 200px;
}
.category_url {
  width: 400px;
  min-width: 200px;
}
.ql-editing {
  left: 1px !important;
}

.ql-container .ql-editor {
  min-height: 225px !important;
}
.content-manage {
  padding: 0px !important;
  padding-top: 0px !important;
}

.quill-text {
  min-height: 225px !important;
}

.ql-editor {
  min-height: 225px !important;
}

.wrap {
  word-break: break-word;
}
.img {
  /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
  /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
  -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
  -webkit-user-select: none;
  -ms-user-select: none; /* From IE10 only */
  user-select: none; /* Not valid CSS yet, as of July 2012 */

  -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
}
</style>
